import React from 'react'
import parse from 'html-react-parser'
import styled from 'styled-components'

const StyledContent = styled.div`

  & a{
      text-decoration: underline;
    }
`

const Content = ({ content, className }) => (
  <StyledContent className={className}>{parse(content)}</StyledContent>
)

export default Content
