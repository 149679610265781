/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Content from 'components/shared/Content'
import CustomLink from 'components/shared/CustomLink'
import HeroDefault from 'components/elements/HeroDefault'
import TitleDefault from 'components/elements/TitleDefault'
import Testimonials from 'components/Testimonials'
import ParseContent from 'components/shared/ParseContent'

// Images
import ChevronRight from 'img/chevron-right.svg'
import DownloadIcon from 'img/download-icon.svg'

const Container = styled.div`
  position: relative;
`

const Anchor = css`
  display: block;
  margin-bottom: 10px;
`

const DownloadSection = styled.div`
  background-color: ${props => props.theme.color.secondary};
`

const Download = styled.a`
  ${Anchor};
  background-color: ${props => props.theme.color.light};
  box-shadow: 0px 0px 4px #00000080;
  border-bottom-left-radius: 20px;
  transition: transform 0.25s;

  &:hover {
    transform: scale(1.05);
    & span {
      color: ${props => props.theme.color.text.contrast};
    }
  }

  @media (min-width: 991px){
    max-width: 319px;
  }
`

const DownloadTitle = styled.h3`
  background-color: ${props => props.theme.color.grey};
  font-family: ${props => props.theme.font.family.secondaryAlt};
  font-size: ${props => props.theme.font.size.xm};
  color: ${props => props.theme.color.text.secondary};
`

const DownloadImage = styled.div`
  position: relative;

  & p {
    position: absolute;
    right: 1rem;
    margin: 0;
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.color.text.main};
    text-align: right;
  }
`

const DownloadText = styled.span`
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.l};
  color: ${props => props.theme.color.text.secondary};
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        bannerImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        downloads {
          files
        }

        testimonial {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          quote
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
    downloads: allWordpressWpDownloadFiles {
      edges {
        node {
          wpId: wordpress_id
          title
          acf {
            description
            file {
              url {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoastMeta, acf },
    downloads,
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={acf.bannerImage.localFile.childImageSharp.fluid.src}
        article
      />
      <HeroDefault image={acf.bannerImage} />
      <section>
        <Container className="container py-5">
          <div className="row">
            <div className="col">
              <TitleDefault h1>{title}</TitleDefault>
            </div>
          </div>
        </Container>
      </section>
      <DownloadSection>
        <Container className="container py-5">
          <div className="row pt-5">
            {acf.downloads.files.map(fileId => {
              const download = downloads.edges.find(
                ({ node: { wpId } }) => wpId === fileId
              )

              return (
                <div key={download.node.wpId} className="col-lg-4 col-md-6">
                  <Download className="mb-5">
                    <CustomLink to={download.node.acf.file.url.localFile.publicURL}>
                      <DownloadTitle className="text-right pr-4 py-2"><Content content={download.node.title} /></DownloadTitle>
                      <DownloadImage className="d-flex justify-content-between align-items-end py-4 px-3">
                        <div className="d-flex align-items-center">
                          <img src={ChevronRight} className="mr-2" alt="" /> 
                          <DownloadText>Download direct</DownloadText>
                        </div>
                        <div>
                          <ParseContent content={download.node.acf.description} />
                          <img src={DownloadIcon} alt="" />
                        </div>
                      </DownloadImage>
                    </CustomLink>
                  </Download>
                </div>
              )
            })}
          </div>
        </Container>
      </DownloadSection>
      <section>
        <Testimonials
          id={[acf.testimonial.quote]}
          image={acf.testimonial.image.localFile.childImageSharp.fluid}
        />
      </section>
    </Layout>
  )
}

export default PageTemplate
